import {useEffect, useState, useRef, useContext} from 'react'; 
import Loader from "@/components/loading_animation.js";
import debounce from "lodash.debounce";
import {ProductCacheContext } from '@/lib/product_cache_context';
export default function InfiniteScroll({itemsPerPage, pageRangeDisplayed, totalCount,  search, loading}) {
  const prodCache = useContext(ProductCacheContext); 
  const currentPage = prodCache.page ? parseInt(prodCache.page, 10) : 1;

  const intersectIndicator = useRef( );
  
  const setNewPage = debounce((currentPage, search) => {
    prodCache.setPage(currentPage + 1 );
    search.appendPage(currentPage + 1) }, [1000])
  const lastPage = () => {

    return (Math.ceil(totalCount /  itemsPerPage) || 1)   ===  currentPage

  }
  const observer = typeof window != 'undefined' &&  new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting  && !lastPage() ) {
        setNewPage(currentPage, search)
      }
    })
  }, {root: null, rootMargin: '0% 0% 100% 0%', threshold: [0.5]})

  useEffect(() => {
    if (intersectIndicator.current) {
      observer?.observe(intersectIndicator.current )
    }

    return () => {
      observer?.disconnect()

    }

  }, [loading, totalCount])

  return (
    <div className='lexcms-infinite-scroll-container'>
      {!loading &&
      <div ref={intersectIndicator}>
        &nbsp;
      </div>
      }

      {loading &&
        <Loader />
      }
    </div>
  ); 

}


