import Image from "next/image";

const Loader = ({children}) => {
  return (
    <div className="lexcms-search-loader-overlay">
      <div className="lexcms-search-loader-spinner"></div>
      {children}
    </div>
  );
};

export default Loader;
